// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-coc-reporting-jsx": () => import("/opt/build/repo/src/pages/coc-reporting.jsx" /* webpackChunkName: "component---src-pages-coc-reporting-jsx" */),
  "component---src-pages-code-of-conduct-jsx": () => import("/opt/build/repo/src/pages/code-of-conduct.jsx" /* webpackChunkName: "component---src-pages-code-of-conduct-jsx" */),
  "component---src-pages-faq-jsx": () => import("/opt/build/repo/src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-schedule-jsx": () => import("/opt/build/repo/src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-travel-jsx": () => import("/opt/build/repo/src/pages/travel.jsx" /* webpackChunkName: "component---src-pages-travel-jsx" */)
}

